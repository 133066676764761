// Generated by Framer (97d1eee)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["QGzeW0jhm"];

const serializationHash = "framer-CsSWW"

const variantClassNames = {QGzeW0jhm: "framer-v-563qm1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, thumbnailImage, width, ...props}) => { return {...props, jeScPUMjL: thumbnailImage ?? props.jeScPUMjL} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;thumbnailImage?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jeScPUMjL, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "QGzeW0jhm", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-563qm1", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"QGzeW0jhm"} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", sizes: "1px", ...toResponsiveImage(jeScPUMjL)}} className={"framer-16ay17m"} data-framer-name={"Photo"} layoutDependency={layoutDependency} layoutId={"vayvvFJyF"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CsSWW.framer-ij01ld, .framer-CsSWW .framer-ij01ld { display: block; }", ".framer-CsSWW.framer-563qm1 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: wrap; gap: 40px; height: 280px; justify-content: flex-start; padding: 0px; position: relative; width: 280px; }", ".framer-CsSWW .framer-16ay17m { aspect-ratio: 1 / 1; flex: none; height: 100%; position: relative; width: var(--framer-aspect-ratio-supported, 280px); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-CsSWW.framer-563qm1 { gap: 0px; } .framer-CsSWW.framer-563qm1 > * { margin: 0px; margin-left: calc(40px / 2); margin-right: calc(40px / 2); } .framer-CsSWW.framer-563qm1 > :first-child { margin-left: 0px; } .framer-CsSWW.framer-563qm1 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 280
 * @framerIntrinsicWidth 280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"jeScPUMjL":"thumbnailImage"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerzSiGuDo34: React.ComponentType<Props> = withCSS(Component, css, "framer-CsSWW") as typeof Component;
export default FramerzSiGuDo34;

FramerzSiGuDo34.displayName = "Project Item";

FramerzSiGuDo34.defaultProps = {height: 280, width: 280};

addPropertyControls(FramerzSiGuDo34, {jeScPUMjL: {title: "Thumbnail Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerzSiGuDo34, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})